var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"game-list-card-wrapper box",class:{ disab: _vm.game.archived }},[_c('div',{staticClass:"columns is-vcentered is-marginless is-paddingless is-mobile",class:{ 'is-disabled': _vm.game.archived }},[_c('div',{staticClass:"column is-narrow is-hidden-mobile"},[_c('link-modal',{attrs:{"link":_vm.getShortLink}})],1),_c('router-link',{staticClass:"column",attrs:{"to":("/game/" + (_vm.game.id))}},[_c('h1',{staticClass:"title is-5"},[_vm._v(" "+_vm._s(_vm.game.name)+" ")]),_c('h2',{staticClass:"subtitle has-text-primary is-6"},[_c('b-icon',{attrs:{"icon":"clock-outline","size":"is-small"}}),_vm._v(" "+_vm._s(_vm.creationDate)+" ")],1)]),_c('div',{staticClass:"column is-narrow"},[(
          _vm.game.params.playTimer && !_vm.game.finished && _vm.game.type === 'beergame'
        )?_c('timer-clock',{attrs:{"tooltip":"Time left for current turn","active":_vm.game.params.playTimer,"timerNextTick":_vm.game.params.timerNextTick,"in-game":false}}):_vm._e(),(_vm.game.type === 'leangame')?_c('timer-elapsed',{attrs:{"show-clock":true,"is-active":_vm.game.params.game_start_at && !_vm.game.params.game_end_at ? true : false,"start-sec":_vm.game.params.game_start_at ? _vm.game.params.game_start_at.seconds : 0,"end-sec":_vm.game.params.game_end_at ? _vm.game.params.game_end_at.seconds : 0}}):_vm._e()],1),_c('div',{staticClass:"column is-narrow is-paddingless"},[(_vm.game.archived)?_c('div',{staticClass:"has-text-dark vcenter-block"},[_c('b-icon',{attrs:{"icon":"checkbox-blank-circle","size":"is-small"}}),_c('span',{staticClass:"is-hidden-touch"},[_vm._v("archived")])],1):_vm._e(),(!_vm.game.archived)?_c('div',{staticClass:"vcenter-block",class:{
          'has-text-success': !_vm.game.finished,
          'has-text-danger': _vm.game.finished
        }},[_c('b-icon',{staticClass:"is-hidden-touch",attrs:{"icon":"checkbox-blank-circle","size":"is-small"}}),_c('span',{staticClass:"is-hidden-touch"},[_vm._v(_vm._s(_vm.game.finished ? "finished" : "active"))]),(_vm.game.params && _vm.game.type === 'beergame')?_c('span',[_c('b-icon',{attrs:{"size":"is-small","icon":"flag"}}),_c('span',{staticClass:"is-hidden-touch"},[_vm._v("week")]),_vm._v(" "+_vm._s(_vm.game.week))],1):_vm._e()],1):_vm._e(),_c('div',{staticClass:"has-text-grey vcenter-block"},[(!_vm.game.archived && !_vm.game.finished)?_c('span',[_c('b-tooltip',{attrs:{"label":_vm.playersNamesTooltip,"active":_vm.playersNamesTooltip ? true : false,"type":"is-dark","position":"is-top","multilined":"","size":"is-small"}},[_c('b-icon',{attrs:{"icon":"account","size":"is-small"}}),_c('span',[_vm._v(" "+_vm._s(_vm.connectedPlayers)+"/"+_vm._s(_vm.totalPlayers)+" ")]),_c('span',{staticClass:"is-hidden-touch"},[_vm._v("players")])],1),(
              _vm.ordersNotValidated &&
                _vm.connectedPlayers &&
                _vm.game.type === 'beergame'
            )?_c('span',[_c('b-icon',{attrs:{"icon":"clipboard-text","size":"is-small"}}),_vm._v(" "+_vm._s(_vm.connectedPlayers - _vm.ordersNotValidated.length)+"/"+_vm._s(_vm.connectedPlayers)+" "),_c('span',{staticClass:"is-hidden-touch"},[_vm._v("orders")])],1):_vm._e()],1):_vm._e(),(!_vm.game.archived && _vm.gameCost && !_vm.gameRevenue && _vm.game.week > 0)?_c('span',[_c('b-icon',{attrs:{"icon":"finance","size":"is-small"}}),_c('span',{staticClass:"is-hidden-touch"},[_vm._v("Cost :")]),_c('span',{staticClass:"has-text-danger"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.gameCost,_vm.game.params.currency))+" ")])],1):_vm._e()]),(
          !_vm.game.archived &&
            _vm.gameCost &&
            _vm.gameRevenue &&
            _vm.game.week > 0 &&
            _vm.game.type === 'beergame'
        )?_c('div',{staticClass:"has-text-grey vcenter-block"},[_c('span',[_c('b-icon',{attrs:{"icon":"finance","size":"is-small"}}),_c('span',{staticClass:"is-hidden-touch"},[_vm._v("Revenue :")]),_c('span',{staticClass:"has-text-info"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.gameRevenue,_vm.game.params.currency))+" ")])],1),_c('span',{staticClass:"pl-2"},[_vm._v("-")]),_c('span',[_c('b-icon',{attrs:{"icon":"finance","size":"is-small"}}),_c('span',{staticClass:"is-hidden-touch"},[_vm._v("Cost :")]),_c('span',{staticClass:"has-text-danger"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.gameCost,_vm.game.params.currency))+" ")])],1),_c('b',[_c('b-icon',{attrs:{"icon":"arrow-right","size":"is-small"}}),_c('span',{staticClass:"is-hidden-touch"},[_vm._v("Profit :")]),_c('span',{class:{
              'has-text-success': _vm.gameRevenue > _vm.gameCost,
              'has-text-danger': _vm.gameRevenue < _vm.gameCost
            }},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.gameRevenue - _vm.gameCost),_vm.game.params.currency))+" ")])],1)]):_vm._e()]),(_vm.isInstructor)?_c('div',{staticClass:"column is-narrow ml5"},[_c('a',{staticClass:"has-text-grey-darker",on:{"click":function($event){_vm.isSettingsModalActive = true}}},[_c('b-icon',{attrs:{"icon":"cog"}})],1)]):_vm._e()],1),(
      _vm.connectedPlayers > 0 &&
        !_vm.game.finished &&
        _vm.ordersNotValidated.length &&
        _vm.game.type === 'beergame'
    )?_c('div',{staticClass:"has-text-grey has-text-centered is-hidden-mobile"},[_vm._v(" "+_vm._s(_vm.waitingForTooltip)+" ")]):_vm._e(),_c('b-modal',{attrs:{"active":_vm.isSettingsModalActive,"has-modal-card":""},on:{"update:active":function($event){_vm.isSettingsModalActive=$event}}},[(_vm.game && _vm.game.type === 'beergame')?_c('bg-settings',{attrs:{"game":_vm.game}}):_vm._e(),(_vm.game && _vm.game.type === 'leangame')?_c('lean-settings',{attrs:{"game":_vm.game}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }