<template>
  <div class="game-list-card-wrapper box" :class="{ disab: game.archived }">
    <div
      class="columns is-vcentered is-marginless is-paddingless is-mobile"
      :class="{ 'is-disabled': game.archived }"
    >
      <div class="column is-narrow is-hidden-mobile">
        <link-modal :link="getShortLink"></link-modal>
      </div>

      <router-link class="column" :to="`/game/${game.id}`">
        <h1 class="title is-5">
          {{ game.name }}
          <!-- <b-tooltip
            :label="typeNames[game.type] + ' game'"
            type="is-dark"
            position="is-top"
            v-if="typeNames[game.type]"
          >
            <b-icon icon="star" type="is-warning" size="is-small"></b-icon>
          </b-tooltip> -->
        </h1>
        <h2 class="subtitle has-text-primary is-6">
          <b-icon icon="clock-outline" size="is-small"></b-icon>
          {{ creationDate }}
        </h2>
      </router-link>
      <div class="column is-narrow">
        <timer-clock
          v-if="
            game.params.playTimer && !game.finished && game.type === 'beergame'
          "
          tooltip="Time left for current turn"
          :active="game.params.playTimer"
          :timerNextTick="game.params.timerNextTick"
          :in-game="false"
        ></timer-clock>
        <timer-elapsed
          v-if="game.type === 'leangame'"
          :show-clock="true"
          :is-active="
            game.params.game_start_at && !game.params.game_end_at ? true : false
          "
          :start-sec="
            game.params.game_start_at ? game.params.game_start_at.seconds : 0
          "
          :end-sec="
            game.params.game_end_at ? game.params.game_end_at.seconds : 0
          "
        ></timer-elapsed>
      </div>
      <div class="column is-narrow is-paddingless">
        <div class="has-text-dark vcenter-block" v-if="game.archived">
          <b-icon icon="checkbox-blank-circle" size="is-small"></b-icon>
          <span class="is-hidden-touch">archived</span>
        </div>
        <div
          class="vcenter-block"
          v-if="!game.archived"
          :class="{
            'has-text-success': !game.finished,
            'has-text-danger': game.finished
          }"
        >
          <b-icon
            icon="checkbox-blank-circle"
            size="is-small"
            class="is-hidden-touch"
          ></b-icon>
          <span class="is-hidden-touch">{{
            game.finished ? "finished" : "active"
          }}</span>
          <span v-if="game.params && game.type === 'beergame'">
            <b-icon size="is-small" icon="flag"></b-icon
            ><span class="is-hidden-touch">week</span> {{ game.week }}</span
          >
        </div>
        <div class="has-text-grey vcenter-block">
          <span v-if="!game.archived && !game.finished">
            <b-tooltip
              :label="playersNamesTooltip"
              :active="playersNamesTooltip ? true : false"
              type="is-dark"
              position="is-top"
              multilined
              size="is-small"
            >
              <b-icon icon="account" size="is-small"></b-icon>
              <span> {{ connectedPlayers }}/{{ totalPlayers }} </span>
              <span class="is-hidden-touch">players</span>
            </b-tooltip>
            <span
              v-if="
                ordersNotValidated &&
                  connectedPlayers &&
                  game.type === 'beergame'
              "
            >
              <b-icon icon="clipboard-text" size="is-small"></b-icon>
              {{ connectedPlayers - ordersNotValidated.length }}/{{
                connectedPlayers
              }}
              <span class="is-hidden-touch">orders</span>
            </span>
          </span>
          <span
            v-if="!game.archived && gameCost && !gameRevenue && game.week > 0"
          >
            <b-icon icon="finance" size="is-small"></b-icon>
            <span class="is-hidden-touch">Cost :</span>
            <span class="has-text-danger">
              {{ gameCost | currency(game.params.currency) }}
            </span>
          </span>
        </div>
        <div
          class="has-text-grey vcenter-block"
          v-if="
            !game.archived &&
              gameCost &&
              gameRevenue &&
              game.week > 0 &&
              game.type === 'beergame'
          "
        >
          <span>
            <b-icon icon="finance" size="is-small"></b-icon>
            <span class="is-hidden-touch">Revenue :</span>
            <span class="has-text-info">
              {{ gameRevenue | currency(game.params.currency) }}
            </span>
          </span>
          <span class="pl-2">-</span>
          <span>
            <b-icon icon="finance" size="is-small"></b-icon>
            <span class="is-hidden-touch">Cost :</span>
            <span class="has-text-danger">
              {{ gameCost | currency(game.params.currency) }}
            </span>
          </span>
          <b>
            <b-icon icon="arrow-right" size="is-small"></b-icon>
            <span class="is-hidden-touch">Profit :</span>
            <span
              :class="{
                'has-text-success': gameRevenue > gameCost,
                'has-text-danger': gameRevenue < gameCost
              }"
            >
              {{ (gameRevenue - gameCost) | currency(game.params.currency) }}
            </span>
          </b>
        </div>
      </div>

      <div class="column is-narrow ml5" v-if="isInstructor">
        <a class="has-text-grey-darker" @click="isSettingsModalActive = true"
          ><b-icon icon="cog"></b-icon
        ></a>
      </div>
    </div>
    <div
      v-if="
        connectedPlayers > 0 &&
          !game.finished &&
          ordersNotValidated.length &&
          game.type === 'beergame'
      "
      class="has-text-grey has-text-centered is-hidden-mobile"
    >
      {{ waitingForTooltip }}
    </div>
    <b-modal :active.sync="isSettingsModalActive" has-modal-card>
      <bg-settings
        :game="game"
        v-if="game && game.type === 'beergame'"
      ></bg-settings>
      <lean-settings
        :game="game"
        v-if="game && game.type === 'leangame'"
      ></lean-settings>
    </b-modal>
  </div>
</template>

<script>
import LinkModal from "@/components/modal/LinkModal.vue";
import BgSettings from "@/components/bg/Play/BgSettings.vue";
import LeanSettings from "@/components/lean/Play/LeanSettings.vue";
import TimerClock from "@/components/Dashboard/TimerClock.vue";
import TimerElapsed from "@/components/lean/Play/StationElements/TimerElapsed.vue";

export default {
  name: "game-list-card",
  components: {
    LinkModal,
    BgSettings,
    LeanSettings,
    TimerClock,
    TimerElapsed
  },
  props: {
    game: Object,
    expand: Boolean,
    isInstructor: Boolean
  },
  computed: {
    zensimuSite() {
      return this.$store.getters.zensimuSite;
    },
    creationDate() {
      let crea = this.game.created_at
        ? new Date(this.game.created_at.seconds * 1000)
        : new Date();
      return crea.toLocaleDateString();
    },
    connectedPlayers() {
      return this.game.players
        ? this.game.players.length
        : this.game.connectedPlayers;
    },
    playersNamesTooltip() {
      let stringNames = "";
      Object.values(this.game.facilities).forEach(faci => {
        if (faci.user) {
          if (stringNames.length) {
            stringNames += ", ";
          }
          stringNames +=
            faci.name + (faci.playerName ? " (" + faci.playerName + ")" : "");
        }
      });
      return stringNames;
    },
    totalPlayers() {
      return this.game.totalPlayers
        ? this.game.totalPlayers
        : Object.keys(this.game.facilities).length;
    },
    gameCost() {
      if (!this.game.params) {
        return false;
      }
      let sumCost = 0;
      Object.keys(this.game.facilities).forEach(faciKey => {
        sumCost += this.game.facilities[faciKey].cost;
      });
      return sumCost;
    },
    gameRevenue() {
      if (!this.game.params) {
        return false;
      }
      let sumRevenue = 0;
      Object.keys(this.game.facilities).forEach(faciKey => {
        sumRevenue += this.game.facilities[faciKey].revenue;
      });
      return sumRevenue;
    },
    ordersNotValidated() {
      if (!this.game.params) {
        return false;
      }
      return Object.values(this.game.facilities)
        .filter(faci => {
          return !faci.validatedOrder && faci.user;
        })
        .map(faci => faci.name);
    },
    waitingForTooltip() {
      if (this.ordersNotValidated.length == 0) {
        return "";
      } else if (this.ordersNotValidated.length == 1) {
        return "Waiting for the " + this.ordersNotValidated[0] + " to order";
      } else {
        return (
          "Waiting for the " +
          this.ordersNotValidated.slice(0, -1).join(", ") +
          " and " +
          this.ordersNotValidated.slice(-1) +
          " to order"
        );
      }
    },
    getShortLink() {
      if (this.$store.getters.portalLook) {
        return `https://${this.$store.getters.portalLook.slug}.zensimu.com/game/${this.game.id}`;
      } else if (this.zensimuSite) {
        if (this.game.zsShortLink) {
          return this.game.zsShortLink;
        } else {
          return `https://play.zensimu.com/game/${this.game.id}`;
        }
      } else {
        return this.game.shortLink;
      }
    }
  },
  data() {
    return {
      isSettingsModalActive: false,
      typeNames: {
        bgadvanced: "Advanced",
        bgpremium: "Premium"
      }
    };
  }
};
</script>

<style lang="scss">
.game-list-card-wrapper.box {
  padding: 0.2em 1em;
  margin: 0;
  transition: box-shadow 0.1s ease-in-out;
  border-radius: 2px;
  .vcenter-block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 5px;
    span {
      padding-left: 2px;
    }
    .icon {
      margin-left: 8px;
      margin-right: 2px;
    }
    .b-tooltip .icon {
      margin-top: 3px;
    }
  }
  .subtitle {
    padding-top: 2px;
  }
  &:hover:not(.disab) {
    box-shadow: 0 0em 1em 0.1em rgba(#000, 0.1), 0 0px 0 1px rgba(#000, 0.1);
  }
  .ml5 {
    margin-left: 0.5em;
  }
}
</style>
