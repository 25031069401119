<template>
  <div
    class="profile-page"
    :style="{ 'background-color': custoLook.bodyBckColor }"
  >
    <section class="section">
      <div class="container">
        <h1
          class="title"
          v-if="
            currentWorkspace &&
              (currentWorkspace.shortLink || currentWorkspace.zsShortLink)
          "
        >
          To join, Go to :
          <em class="has-text-success">{{
            zensimuSite && currentWorkspace.zsShortLink
              ? currentWorkspace.zsShortLink.replace("https://", "")
              : currentWorkspace.shortLink.replace("https://", "")
          }}</em>
        </h1>
        <article class="columns is-centered mb-0">
          <div class="column is-four-fifths">
            <b-field grouped class="mb-0">
              <b-field expanded>
                <b-input
                  rounded
                  placeholder="Filter games by name..."
                  type="search"
                  size="is-medium"
                  icon="magnify"
                  v-model="searchText"
                >
                </b-input>
              </b-field>
              <p class="control">
                <b-button
                  rounded
                  @click="joinRandomPosition"
                  size="is-medium"
                  type="is-black"
                  :style="{
                    'background-color': custoLook.btnBestColor,
                    color: 'white'
                  }"
                  >Join a Random Position</b-button
                >
              </p>
            </b-field>
            <b-notification
              :active="showError"
              @close="closeError"
              type="is-danger"
              >{{ showError ? error.message : "" }}</b-notification
            >
          </div>
        </article>
        <article class="columns is-centered"></article>
        <ul class="columns is-multiline is-centered is-relative">
          <li
            class="game-list-row column is-four-fifths"
            v-bind:key="game.id"
            v-for="(game, index) in filterUserGames"
          >
            <div v-if="showMonth(index)" class="columns is-mobile is-vcentered">
              <h1 class="is-size-4 has-text-primary column is-narrow">
                {{ showMonth(index) }}
              </h1>
              <div class="column has-background-primary"></div>
            </div>
            <game-list-card
              :key="game.id"
              :game="game"
              :is-instructor="authUser && authUser.uid === game.creator"
            ></game-list-card>
          </li>
          <li v-if="!extUserGamesList.length">
            <div class="section"><br /></div>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GameListCard from "@/components/Dashboard/GameListCard.vue";
import { sortGames } from "@/store/helperFunctions.js";

export default {
  name: "userPageList",
  components: { GameListCard },
  computed: {
    ...mapGetters([
      "authUser",
      "extUserGamesList",
      "custoLook",
      "currentWorkspace",
      "showError",
      "error",
      "zensimuSite"
    ]),
    filterUserGames() {
      return [...this.extUserGamesList]
        .filter(game => {
          let findText = true;
          if (this.searchText.length) {
            findText =
              game.name.toLowerCase().indexOf(this.searchText.toLowerCase()) >=
              0;
          }
          return !game.archived && findText;
        })
        .sort(sortGames);
    }
  },
  methods: {
    closeError() {
      this.$store.commit("clearError");
    },
    joinRandomPosition() {
      let availPos = [];
      this.filterUserGames.forEach(game => {
        if (!game.finished) {
          Object.values(game.facilities).forEach(faci => {
            if (!faci.user) {
              availPos.push({
                id: game.id,
                name: game.name,
                roleId: faci.id,
                roleName: faci.name
              });
            }
          });
        }
      });
      if (availPos.length) {
        let choosePos = availPos[Math.floor(Math.random() * availPos.length)];
        this.$store
          .dispatch("joinGame", choosePos)
          .then(payload => {
            this.connectTrials = 0;
            this.$buefy.dialog.alert({
              size: "is-medium",
              message: `You are joining <b>${payload.name}</b> as <b>${payload.roleName}</b>`,
              confirmText: `Let's go !`,
              type: "is-success",
              onConfirm: () => {
                this.$router.push("/game/" + payload.id + "/play");
              }
            });
          })
          .catch(error => {
            if (
              error.message ===
              "Please leave your current game before joining a new one"
            ) {
              this.connectTrials = 0;
              this.$store.commit("setLoading", false);
              this.$store.commit("setError", {
                message: error.message
              });
            } else {
              if (this.connectTrials < 30) {
                if (this.connectTrials === 0) {
                  this.$buefy.toast.open({
                    duration: 5000,
                    queue: false,
                    message: `Looking for an available position, this shouldn't take long...`
                  });
                }
                this.connectTrials += 1;
                this.joinRandomPosition();
              } else {
                this.connectTrials = 0;
                this.$store.commit("setLoading", false);
                if (this.authUser && !this.authUser.email) {
                  this.$store.dispatch("logout");
                }
                this.$store.commit("setError", {
                  message:
                    "Couldn't connect to a game. Try again or select manually one of the games available below."
                });
              }
            }
          });
      } else {
        this.$store.commit("setError", {
          message: "No available position found in the games below"
        });
      }
    },
    showMonth(index) {
      let monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      let creaDate = this.filterUserGames[index].created_at
        ? new Date(this.filterUserGames[index].created_at.seconds * 1000)
        : new Date();
      let result =
        monthNames[creaDate.getMonth()] + " " + creaDate.getFullYear();
      if (index === 0) {
        return result;
      } else {
        let creaDateBefore = this.filterUserGames[index - 1].created_at
          ? new Date(this.filterUserGames[index - 1].created_at.seconds * 1000)
          : new Date();
        let beforeMonth =
          creaDateBefore.getYear() * 100 + creaDateBefore.getMonth();
        let myMonth = creaDate.getYear() * 100 + creaDate.getMonth();
        if (beforeMonth !== myMonth) {
          return result;
        }
      }
      return false;
    }
  },
  data() {
    return {
      connectTrials: 0,
      searchText: ""
    };
  }
};
</script>

<style lang="scss">
.profile-page {
  .mb1 {
    margin-bottom: 1em;
  }
  ul {
    position: relative;
    li.game-list-row {
      padding: 0.75em 0;
    }
    .has-background-primary {
      height: 1px;
      padding: 0;
      margin: 0 1.5em;
    }
  }
}
</style>
