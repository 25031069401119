<template>
  <div class="link-modal">
    <a @click="clickQR" class="vcenter-block"
      ><img
        :min-width="qrSize || '38px'"
        :width="qrSize || '38px'"
        :src="qrlogo"
    /></a>
    <b-modal :active.sync="showQRModal" has-modal-card>
      <div class="modal-card has-text-centered">
        <section class="modal-card-body section">
          <!-- <button
            class="delete"
            aria-label="close"
            @click="$parent.close()"
          ></button> -->
          <h1 class="title is-size-4 is-size-5-mobile has-text-success">
            {{ link }}
          </h1>

          <img
            width="300"
            max-width="50%"
            :src="
              'https://chart.googleapis.com/chart?cht=qr&chs=200x200&chl=' +
                link
            "
          />
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
import qrlogo from "@/assets/game/qrlogo.png";

export default {
  name: "LinkModal",
  props: {
    game: Object,
    link: String,
    qrSize: Number
  },
  methods: {
    clickQR() {
      this.showQRModal = true;
    }
  },
  data() {
    return {
      showQRModal: false,
      qrlogo: qrlogo
    };
  }
};
</script>

<style lang="scss">
.link-modal {
  display: inline-block;

  .title {
    margin: 0;
    margin-top: 0.5em;
  }
}
</style>
